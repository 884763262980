import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "../../../components/Toast";


const LegacyAuthenticateRedirect = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		const requestToken = searchParams.get("requestToken");
		if (requestToken) {
			navigate(`/auth/requestToken/${requestToken}`, { replace: true });
		} else {
			toast.error(<Toast title="Missing request token" message="No request token in query" />)
			navigate("/", { replace: true });
		}
	}, [searchParams, navigate]);
	return null


};
export default LegacyAuthenticateRedirect;