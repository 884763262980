import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import { XCircle } from '../../iconLibrary/svgIconLibrary'; // Assuming you have an error icon

interface ErrorDisplayProps {
	title?: string;
	message: string;
	buttonText?: string;
	redirectPath?: string;
	onButtonClick?: () => void;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({
	title = "Error Occurred",
	message,
	buttonText = "Return to Manage Page",
	redirectPath = "/manage",
	onButtonClick
}) => {
	const navigate = useNavigate();

	const handleClick = () => {
		if (onButtonClick) {
			onButtonClick();
		} else {
			navigate(redirectPath);
		}
	};

	return (
		<div className="flex flex-col items-center justify-center p-8 max-w-md mx-auto">
			<div className="mb-6 text-red-500">
				<XCircle className="w-16 h-16" />
			</div>

			<h2 className="text-2xl font-semibold text-customGray mb-4 text-center">
				{title}
			</h2>

			<div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6 w-full">
				<p className="text-center text-red-700 text-base">
					{message}
				</p>
			</div>

			<Button
				onClick={handleClick}
			>
				{buttonText}
			</Button>
		</div>
	);
};

export default ErrorDisplay; 