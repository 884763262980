import NewHttpClient from "./autogenerated/http_client";

const CreateHttpClient = () => {
	return NewHttpClient({
		baseUrl: "",
		retrieveUserAuth: async () => { return "" },
		retrieveGuestAuth: async () => { return "" },
		retrieveAccessTokenAuth: async () => { throw new Error("not configured") },
		decryptCallback: async () => { throw new Error("not configured") },
		encryptCallback: async () => { throw new Error("not configured") },
		deviceId: ""
	})
}
export default CreateHttpClient
export type SanctumHttpClient = ReturnType<typeof CreateHttpClient>