import React, { forwardRef } from 'react';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> { }

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	({ className, ...props }, ref) => (
		<input
			ref={ref}
			spellCheck={false}
			type="text"
			{...props}
			className={`border-2 border-green-500
				w-full
				outline-none focus:outline-none
				rounded-md px-4 py-2
				bg-darkBackground
				text-gray-200 placeholder-gray-400 ${className || ""}`
			}
		/>
	))

TextInput.displayName = "TextInput";


export default TextInput;