import useEventsInfiniteScroll from "../../hooks/useEventsInfiniteLoader";
import { DisplayEvent } from "../../utils/types";
import { truncateStringMiddle } from "../../utils/utility";
import InfiniteLoader from "../InfiniteLoader";

interface EventsProps {
	events: DisplayEvent[];
}

const Events = ({ events }: EventsProps) => {
	return (
		<div className="bg-darkBackground p-4 shadow-md">
			<ul className="space-y-3">
				{events.map((event) => (
					<li
						key={`${event.type}${event.datestring}${event.nevent}`}
						className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-3 bg-gray-800 rounded-lg hover:bg-gray-700 transition-all"
					>
						{/* Date */}
						<span className="text-sm text-gray-400 sm:mr-4">{event.datestring}</span>

						{/* Event Type and Truncated Name */}
						<span className="flex-1 text-gray-300 text-sm font-medium truncate sm:truncate-none sm:text-center">
							<span className="text-green-500">{event.type}</span>&nbsp;
							{truncateStringMiddle(event.nevent)}
						</span>

						{/* Country Flag and State */}
						<span className="flex items-center mt-2 sm:mt-0 sm:ml-4 text-gray-300 text-sm">
							<img
								src={event.flagSrc}
								alt={event.flagAlt}
								className="w-6 h-auto rounded-sm shadow-sm mr-2"
							/>
							{event.state}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
};



interface Props {
	accessToken: string
}

const FullEvents: React.FC<Props> = ({ accessToken }) => {


	const { isLoading, loadMoreCallback, dynamicEvents, isLastPage } =
		useEventsInfiniteScroll(10, accessToken);

	return (
		<>
			<Events events={dynamicEvents} />


			<InfiniteLoader
				isLoading={isLoading}
				isLastPage={isLastPage}
				loadMoreCallback={loadMoreCallback}
			/>
		</>
	);
};




export default FullEvents;
