import { FC, ReactNode } from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { Route, Routes, BrowserRouter, Navigate, useLocation } from "react-router-dom";
import Layout from "./layout";
import Verify from "./pages/verify";
import Confirmation from "./pages/confirmation";
import Nsec from "./pages/nsec";
import "./index.css";
import "typeface-montserrat";
import "react-toastify/dist/ReactToastify.css";
import Manage from "./pages/manage";
import Authenticate from "./pages/authenticate";
import { store, persistor, useSelector } from "./redux/index";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import GlobalStatesContainer from "./components/globalStatesContainer";
import Home from "./pages/home";
import RequestTokenFlow from "./pages/auth/request";
import LegacyAuthenticateRedirect from "./pages/auth/LegacyRoute";


interface ProtectedRouteProps {
  children: ReactNode;
}


const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();
  const identifier = useSelector(state => state.user.userInfo.identifier);

  return identifier ? (
    <>{children}</>
  ) : (
    <Navigate to="/auth/email" state={{ from: location }} replace />
  );
};

const GuestRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const identifier = useSelector(state => state.user.userInfo.identifier);

  return !identifier ? (
    <>{children}</>
  ) : (
    <Navigate to="/manage" replace />
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      <GlobalStatesContainer>
        <BrowserRouter>
          <Routes>
            {/* Routes with Layout */}
            <Route path="/" element={<Layout />}>
              <Route index element={<GuestRoute><Home /></GuestRoute>} />
              <Route path="manage" element={<ProtectedRoute><Manage /></ProtectedRoute>} />
              <Route path="auth">
                <Route path="verify/:submitToken/:requestToken?" element={<GuestRoute><Verify /></GuestRoute>} />
                <Route path="confirmation/:requestToken" element={<ProtectedRoute><Confirmation /></ProtectedRoute>} />
                <Route path="requestToken/:requestToken" element={<RequestTokenFlow />} />
                <Route path="nsec/:requestToken?" element={<GuestRoute><Nsec /></GuestRoute>} />
                <Route path="email/:requestToken?" element={<GuestRoute><Authenticate /></GuestRoute>} />
              </Route>
              <Route path="authenticate" element={<LegacyAuthenticateRedirect />} />
            </Route>
          </Routes>
          <ToastContainer
            position="top-center"
            closeOnClick
            pauseOnHover
            autoClose={4000}
            limit={2}
            pauseOnFocusLoss={true}
          />
        </BrowserRouter>
      </GlobalStatesContainer>
    </PersistGate>
  </Provider>
);
