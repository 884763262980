import { configureStore, combineReducers, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "./userSlice"
import { createMigrate, persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage";
import { useDispatch as originalUseDispatch, useSelector as originalUseSelector } from 'react-redux';



const rootReducer = combineReducers({
	user: userReducer
});




const migrations: Record<number, () => any> = {
	0: () => {
		return {
			user: {
				userInfo: {
					accessTokens: [],
					keySlots: {},
					identifier: ""
				}
			}
		}
	}
}



const persistConfig = {
	key: "root",
	storage,
	whitelist: ["user"],
	version: 0,
	migrate: createMigrate(migrations, { debug: false })
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ['persist/PERSIST'],
			},
		}),
})

export type State = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useDispatch: () => AppDispatch = originalUseDispatch
export const useSelector = <TSelected = unknown>(
	selector: (state: State) => TSelected,
	equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected => originalUseSelector<State, TSelected>(selector, equalityFn);

export type ReducerAction = ThunkAction<Promise<void>, State, undefined, Action<any>>;

const persistor = persistStore(store)

export { store, persistor }