import React, { ReactNode, useEffect, useRef, useState } from 'react';

interface Props<T> {
	options: { value: T, jsx: ReactNode }[];
	selectedOption?: T;
	onSelect: (option: T) => void;
}


const Dropdown = <T,>({ options, selectedOption, onSelect }: Props<T>) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	const handleSelect = (option: T) => {
		onSelect(option);
		setIsOpen(false);
	};

	// dropdown stuff
	const handleClickOutside = (event: MouseEvent) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
			setIsOpen(false);
		}
	};
	useEffect(() => {
		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen]);


	const selected = options.find((item) => item.value === selectedOption)

	return (
		<div ref={dropdownRef} className="relative w-full">
			<div
				className="flex items-center justify-between border border-gray-500 rounded p-2 cursor-pointer select-none"
				onClick={handleToggle}
			>
				<span>{selected?.jsx ?? "Please select"}</span>
				<span className="ml-2">&#9660;</span>
			</div>

			{isOpen && (
				<div className="absolute top-full left-0 w-full border border-gray-500 rounded bg-darkGray1 mt-1 z-10">
					{options.map((option, index) => (
						<div
							key={index}
							className="p-2 hover:bg-green-500 cursor-pointer"
							onClick={() => handleSelect(option.value)}
						>
							{option.jsx}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default Dropdown;
