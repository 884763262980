import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "../../redux";
import { toast } from "react-toastify";
import Toast from "../../components/Toast";
import * as Types from "../../api/autogenerated/types";
import { fetchUserData } from "../../redux/userSlice";
import { getSndLevelDomain } from "../../utils/utility";
import UserAgentIcons from "../../components/UserAgentIcons";
import SanctumClient from "../../api";
import Dropdown from "../../components/Dropdown";
import Page from "../../layout/page";
import { CheckMark } from "../../iconLibrary/svgIconLibrary";
import OutlinedButton from "../../components/OutlinedButton";
import Button from "../../components/Button";
import FooterPortal from "../../components/FooterPortal";
import { ErrorCode } from "../../utils/errors";
import ErrorDisplay from '../../components/ErrorDisplay';

const Confirmation: React.FC = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { requestToken } = useParams() as { requestToken: string }



  const [requestTokenData, setRequestTokenData] = useState<Types.GetRequestTokenDataResponse>();
  const [selectedSlot, setSelectedSlot] = useState(1);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {

    const fetchRequestTokenData = async () => {
      const handler = SanctumClient.getInstance();
      try {
        const res = await handler.GetRequestTokenData(requestToken)
        console.log({ res })
        setRequestTokenData(res)
      } catch (err) {
        const message = err instanceof Error ? err.message : ErrorCode.UNKNOWN_ERROR;
        switch (message) {
          case ErrorCode.REQUEST_TOKEN_NOT_FOUND_OR_EXPIRED:
            const toastMessage = "Request token not found or expired. Please start the flow again"
            setError(toastMessage)
            break;
          default:
            setError(`An unexpected error occurred: ${message}`);
        }

      }
    }
    fetchRequestTokenData();
  }, [navigate, requestToken]);


  const userKeys = useMemo(() => {
    const keys = Object.entries(user.userInfo.keySlots).map(([key, slot]) => ({
      jsx: <div className="flex items-center space-x-2 text-center">
        <span className="text-lg font-semibold">{slot}</span>
        <img src={`https://robohash.org/${key}.png?bgset=bg1&size=50x50`} alt="Npub icon" className="h-6 w-6 rounded-full" />
        <span className="text-base truncate font-medium">{`${key.substring(0, 7)}...${key.substring(key.length - 7, key.length)}`}</span>
      </div>,
      value: slot,
    }));
    return keys;
  }, [user]);

  const handleConfirm = useCallback(async () => {
    if (!requestTokenData) return;
    const handler = SanctumClient.getInstance();

    try {
      await handler.AuthorizeRequestToken(requestToken, selectedSlot);
      dispatch(fetchUserData());
      toast.success(
        <Toast
          title="Success"
          message={`Access Token successfully created for key slot ${selectedSlot}`}
        />
      );
      navigate("/manage", { replace: true });
    } catch (err) {
      const message = err instanceof Error ? err.message : ErrorCode.UNKNOWN_ERROR;

      switch (message) {
        case ErrorCode.REQUEST_TOKEN_NOT_FOUND_OR_EXPIRED:
          setError("Request token not found or expired. Please start the flow again");
          break;
        default:
          setError(`An unexpected error occurred: ${message}`);
      }


      navigate("/manage", { replace: true });
    }
  }, [navigate, requestTokenData, selectedSlot, requestToken, dispatch]);

  if (error) {
    return (
      <Page title="Confirmation">
        <ErrorDisplay
          title="Request Token Error"
          message={error}
          buttonText="Return to Manage Page"
          onButtonClick={() => {
            navigate("/manage", { replace: true });
          }}
        />
      </Page>
    );
  }

  if (!requestTokenData) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <Page title="Confirmation">
      <div className="flex flex-col justify-center items-center my-6">
        <p className="text-customGray font-medium text-justify mb-6">
          An app, identifying itself as{" "}
          {getSndLevelDomain(requestTokenData.domain)} from a{" "}
          <UserAgentIcons ua={requestTokenData.user_agent} /> device, is
          requesting access to use your Nostr keys.
        </p>
        <div className="w-full flex justify-center items-center space-x-2  mb-6">
          <label className="text-2xl font-semibold text-green-500">Slot</label>
          <div className="w-2/3">
            <Dropdown
              selectedOption={selectedSlot}
              onSelect={(slot: number) => setSelectedSlot(slot)}
              options={userKeys}
            />
          </div>

        </div>
        <PrivilageContainer text="Sign and encrypt events" />
        <PrivilageContainer text="Decrypt events" />
        <p className="text-customGray font-medium text-justify mt-6">
          Your private key will not be shared and you may revoke access at
          any time, but this app may impersonate you until then.
        </p>
        <p className="text-customGray font-medium text-justify mt-6">
          Do you trust this app/device?
        </p>
        <div className="flex justify-center items-center mt-3 space-x-4">
          <OutlinedButton onClick={() => navigate("/manage", { replace: true })}>
            Deny
          </OutlinedButton>
          <Button onClick={handleConfirm}>
            Allow
          </Button>

        </div>
      </div>
      <FooterPortal>
        <div className="w-full p-4 text-center text-customGray font-medium text-sm">
          By proceeding you acknowledge that this is bleeding-edge software, and agree to the providers <a href="https://docs.shock.network/terms" target="_blank" rel="noreferrer" className="text-green-500 hover:cursor-pointer hover:underline">terms</a> regarding any services herein.
        </div>
      </FooterPortal>
    </Page>
  )
};

export default Confirmation;

const PrivilageContainer = ({ text }: { text: string }) => (
  <div className="flex items-center space-x-2 mb-4">
    <CheckMark />
    <span className="text-gray-400 text-base">{text}</span>
  </div>
)
