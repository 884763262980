import { useEffect } from "react";
import { useDispatch } from "../../redux";
import { CheckSession } from "../../redux/userSlice";

export default function GlobalStatesContainer({
  children,
}: {
  children: JSX.Element;
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CheckSession());
  }, [dispatch]);

  return <>{children}</>;
}
