import axios from "axios";
import { DisplayEvent } from "./types";
import { AccessTokenRecord } from "../api/autogenerated/types";

export const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export const formatTime = (seconds: number): string => {
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes
        .toString()
        .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export const formatTimestamp = (unixTimestamp: number) => {

    const date = new Date(unixTimestamp);


    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];



    const month = monthNames[date.getMonth()];
    const dayOfWeek = dayNames[date.getDay()]


    const day = date.getDate();


    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');


    //const year = date.getFullYear();

    const formattedDate = `${dayOfWeek} ${month} ${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}



export const getIpInfo = async (ip: string) => {
    const { data } = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=fc151a3bcb55439e8a951bb69f0b0d18&ip=${ip}`);
    return data;
}

export const truncateStringMiddle = (data: string) => {
    return `${data.substring(0, 10)}...${data.substring(data.length - 10, data.length)}`;
}

export const truncateStringEnd = (data: string) => {
    return data.substring(0, 14);
}

export const getSndLevelDomain = (url: string) => {
    const resultLnurl = new URL(url);
    const parts = resultLnurl.hostname.split(".");

    if (parts.length > 2) {
        return resultLnurl.hostname;
    }

    return parts.slice(-2).join(".");
};

export const FormatEventToDisplayEvent = async (events: AccessTokenRecord[]): Promise<DisplayEvent[]> => {


    const formattedEvents: DisplayEvent[] = await Promise.all(events.map(async e => {
        const data = await getIpInfo(e.ip_address);
        return {
            datestring: formatTimestamp(e.timestamp),
            type: e.type,
            nevent: e.nevent,
            flagSrc: data.country_flag,
            flagAlt: data.country_name,
            state: data.state_prov
        }
    }));
    return formattedEvents;

}


export const formatIdentifier = (identifier: string): string => {
    if (identifier.includes('@')) {
        const [localPart, domain] = identifier.split('@');
        if (localPart.length > 3) {
            return `${localPart.slice(0, 3)}...@${domain}`;
        }
        return identifier;
    }


    if (identifier.startsWith('npub')) {
        return `${identifier.slice(0, 10)}...${identifier.slice(-10)}`;
    }

    return identifier;
};

export const extractUrlParams = (param: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}
