import CreateHttpClient, { SanctumHttpClient } from "./http";

export default class SanctumClient {
	constructor() {
		this.sanctumHttp = CreateHttpClient()
	}
	private static instance: SanctumClient | null = null;
	private sanctumHttp: SanctumHttpClient;


	public static getInstance(): SanctumClient {
		if (!SanctumClient.instance) {
			SanctumClient.instance = new SanctumClient();
		}
		return SanctumClient.instance;
	}

	async AuthEmail(email: string, requestToken?: string) {
		const res = await this.sanctumHttp.AuthEmail({ email, request_token: requestToken })
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res
	}

	async AuthorizeRequestToken(requestToken: string, keySlot: number) {
		const res = await this.sanctumHttp.AuthorizeRequestToken({ request_token: requestToken, key_slot: keySlot })
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
	}

	async GetUserInfo() {
		const res = await this.sanctumHttp.GetUserInfo()
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res
	}

	async RequestCallback(submitToken: string, otp: string) {
		const res = await this.sanctumHttp.RequestCallback({ submit_token: submitToken, otp })
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res;
	}

	async GetAccessTokenRecords(accessToken: string, page: number, max: number) {
		const res = await this.sanctumHttp.GetAccessTokenRecords({ access_token: accessToken, page, max })
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res
	}

	async GetRequestTokenData(requestToken: string) {
		const res = await this.sanctumHttp.GetRequestTokenData({ request_token: requestToken })
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res
	}

	async DeleteAccessToken(accessToken: string) {
		const res = await this.sanctumHttp.DeleteAccessToken({ access_token: accessToken })
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
	}

	async GetNostrPublicKey() {
		const res = await this.sanctumHttp.GetNostrPubKey()
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res.pubkey
	}

	async GetNostrRelays() {
		const res = await this.sanctumHttp.GetNostrRelays()
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res.relays
	}

	async SignNostrEvent(unsignedEvent: string) {
		const res = await this.sanctumHttp.SignNostrEvent({ usignedEvent: unsignedEvent })
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res.signedEvent;
	}

	async Nip44Decrypt(ciphertext: string, pubkey: string) {
		const res = await this.sanctumHttp.Nip44Decrypt({ ciphertext, pubkey })
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res.plaintext;
	}
	async Nip44Encrypt(plaintext: string, pubkey: string) {
		const res = await this.sanctumHttp.Nip44Encrypt({ plaintext, pubkey })
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res.ciphertext;
	}
	async Nip98Event(url: string, method: string, requestBody: string) {
		const res = await this.sanctumHttp.Nip98Event({ url, method, request_body: requestBody })
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res.authorization_header;
	}

	async GetAuthRequestInfo(submitToken: string, requestToken?: string) {
		const res = await this.sanctumHttp.GetAuthRequestInfo({ submit_token: submitToken, request_token: requestToken });
		if (res.status === "ERROR") {
			throw new Error(res.reason)
		}
		return res
	}

	async CheckSession() {
		const res = await this.sanctumHttp.CheckSession();
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res;
	}

	async UpdateAccessTokenInfo(accessToken: string, label: string) {
		const res = await this.sanctumHttp.UpdateAccessTokenInfo({ access_token: accessToken, label });
		if (res.status === "ERROR") {
			throw new Error(res.reason);
		}
		return res;
	}

	async AuthWithNsec(nsec: string, requestToken?: string) {
		const res = await this.sanctumHttp.AuthWithNsec({ nostr_secret: nsec, request_token: requestToken })
		if (res.status === "ERROR") {
			throw new Error(res.reason)
		}
		return res
	}
}