
import * as Types from "../../api/autogenerated/types";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  FormatEventToDisplayEvent,
  formatTimestamp,
  getSndLevelDomain,
  truncateStringEnd,
} from "../../utils/utility";
import { nip19 } from "nostr-tools";
import { useDispatch, useSelector } from "../../redux";
import { toast } from "react-toastify";
import Toast from "../Toast";
import { fetchUserData } from "../../redux/userSlice";
import { DisplayEvent } from "../../utils/types";
import * as Icon from "../../iconLibrary/svgIconLibrary";
import SanctumClient from "../../api";
import UserAgentIcons from "../UserAgentIcons";
import ModalWrapper from "../Modal";
import SimpleEvents from "../SimpleEvents";
import Button from "../Button";
import OutlinedButton from "../OutlinedButton";
import FullEvents from "../FullEvents";
import TextInput from "../TextInput";
import useClickOutside from "../../hooks/useClickOutside";
import { ErrorCode } from "../../utils/errors";

interface EditableLabelProps {
  isEditLabel: boolean;
  setIsEditLabel: (isEditLabel: boolean) => void;
  originalLabel: string;
  label: string;
  setLabel: (label: string) => void
}



const EditableLabel = ({ isEditLabel, setIsEditLabel, originalLabel, label, setLabel }: EditableLabelProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useClickOutside([inputRef], () => setIsEditLabel(false));
  return (
    <div>
      {
        !isEditLabel
          ?
          <div className="flex items-center space-x-1 cursor-pointer text-green-500" onClick={() => setIsEditLabel(true)}>
            <Icon.PencilIcon />
            <span>{originalLabel}</span>
          </div>
          :
          <TextInput ref={inputRef} autoFocus type="text" value={label} onChange={(e) => setLabel(e.target.value)} />
      }
    </div>
  )
}

interface Props {
  info: Types.AccessTokenInfo;
  hide: () => void;
}

const AccessTokenModal = ({ info, hide }: Props) => {
  const { userInfo } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [label, setLabel] = useState(info.label);
  const [isEditLabel, setIsEditLabel] = useState(false);


  const [events, setEvents] = useState<DisplayEvent[]>([]);
  const [auditLogsAccessToken, setAuditLogsAccessToken] = useState("");

  useEffect(() => {
    const fetchEvents = async () => {
      const handler = SanctumClient.getInstance();
      try {
        const res = await handler.GetAccessTokenRecords(info.access_token, 1, 5);
        const formattedEvents = await FormatEventToDisplayEvent(res.records);
        setEvents(formattedEvents);
      } catch (err) {
        console.log(err)
      }
    }

    fetchEvents();
  }, [info]);

  const formattedInfo = useMemo(() => {
    const created = formatTimestamp(info.created_at);

    // origin name
    const label = getSndLevelDomain(info.origin);

    // npub encoding
    const npub = nip19.npubEncode(info.public_key);

    return {
      created,
      label,
      npub: truncateStringEnd(npub),
    };
  }, [info]);




  const handleRevokeToken = useCallback(async () => {
    const revoke = window.confirm(
      "Are you sure you want to delete this access token?"
    );
    if (revoke) {
      const handler = SanctumClient.getInstance();
      try {
        await handler.DeleteAccessToken(info.access_token);
        dispatch(fetchUserData());
        hide();
      } catch (err) {
        const message = err instanceof Error ? err.message : ErrorCode.UNKNOWN_ERROR
        switch (message) {
          case ErrorCode.ACCESS_TOKEN_INVALID:
            toast.error(<Toast title='The access token does not exist' message='' />);
            break;
          case ErrorCode.ACCESS_FORBIDDEN:
            toast.error(<Toast title="Not Authorized" message="" />);
            break;
          default:
            toast.error(<Toast title='Unknown Error' message={message} />);
        }
        await dispatch(fetchUserData());
        hide();
      }
    }
  }, [info, dispatch, hide]);

  const handleSave = useCallback(async () => {
    if (label !== info.label) {
      const handler = SanctumClient.getInstance();
      try {
        await handler.UpdateAccessTokenInfo(info.access_token, label);
        dispatch(fetchUserData());
        hide();
      } catch (err) {
        const message = err instanceof Error ? err.message : ErrorCode.UNKNOWN_ERROR
        switch (message) {
          case ErrorCode.ACCESS_TOKEN_INVALID:
            toast.error(<Toast title='The access token does not exist' message='' />);
            break;
          case ErrorCode.ACCESS_FORBIDDEN:
            toast.error(<Toast title="Not Authorized" message="" />);
            break;
          default:
            toast.error(<Toast title='Unknown Error' message={message} />);
        }
        dispatch(fetchUserData());
        hide();
      }

    } else {
      hide();
    }
  }, [info, label, dispatch, hide])

  return (

    <div className="w-full flex flex-col justify-center items-center">
      <span className="mb-6 text-white text-base font-normal">Client_ID-{info.client_key}</span>
      <div className="w-full flex flex-col justify-center items-center space-y-1">
        <InfoRow label="Created" value={formattedInfo.created} />
        <InfoRow label="Origin domain" value={formattedInfo.label} />
        <InfoRow
          label="Label"
          value={<EditableLabel
            setIsEditLabel={setIsEditLabel}
            isEditLabel={isEditLabel}
            setLabel={setLabel}
            label={label}
            originalLabel={info.label}
          />}
        />
        <InfoRow label={`Key Slot ${userInfo.keySlots[info.public_key]}`} value={formattedInfo.npub} />
        <InfoRow label="User Agent" value={<UserAgentIcons ua={info.user_agent} />} />
        <InfoRow label="Latest Events:" value="" />

      </div>
      <SimpleEvents events={events} />

      <div
        className="underline text-green-500 my-4 font-medium"
        onClick={() => setAuditLogsAccessToken(info.access_token)}
      >
        Audit Log
      </div>

      <div className="flex items-center space-x-3">
        <OutlinedButton className="border-red-700 hover:bg-red-500" onClick={handleRevokeToken}>
          Revoke
        </OutlinedButton>
        <Button onClick={handleSave}>Save</Button>
      </div>
      <ModalWrapper
        isOpen={auditLogsAccessToken !== ""}
        onClose={() => setAuditLogsAccessToken("")}
        modalIndex={1}
        className="!p-0"
      >
        <FullEvents accessToken={auditLogsAccessToken} />
      </ModalWrapper>

    </div>
  );
};

export default AccessTokenModal;

interface InfoRowProps {
  label: string,
  value: string | ReactNode
}

const InfoRow = ({ label, value, ...props }: InfoRowProps & React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={`
			flex justify-between items-center
      w-full
      text-base font-medium
      space-x-2
			${props.onClick ? 'cursor-pointer' : ''} 
		`}
    {...props}
  >
    <span className="text-white">
      {label}
    </span>
    <span className="text-customGray ">{value}</span>
  </div>
);
