import React, { ReactNode } from "react";



interface PageProps {
	title: ReactNode
	children: ReactNode;
}

export const Page: React.FC<PageProps> = ({ title, children }): JSX.Element => {


	return (
		<div className="flex flex-col justify-center items-center px-6 mb-auto">
			<div className="flex flex-col justify-center items-center">
				<h1 className="text-xl md:text-4xl text-center font-medium mt-12">
					{title}
				</h1>
				<div className="border-t border-solid border-1 w-40 border-gray-300 mt-4 mb-8" />
			</div>
			<div className="max-w-lg flex flex-col justify-center my-6">
				{children}
			</div>
		</div>
	)
}

export default Page;

