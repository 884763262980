

import { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// Define the type for the props
interface FooterPortalProps {
	children: ReactNode;
}

const FooterPortal: React.FC<FooterPortalProps> = ({ children }) => {
	const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

	useEffect(() => {
		// Dynamically find the portal root after the component has mounted
		const root = document.getElementById('sanctum-footer');
		setPortalRoot(root);
	}, []);

	// Only render the portal if portalRoot is available
	if (!portalRoot) return null;


	return ReactDOM.createPortal(
		children,
		portalRoot
	);
};

export default FooterPortal;