import { useEffect, useId, useState } from "react";
import { UAParser } from "ua-parser-js";
import android from "../../images/android.png";
import chrome from "../../images/chrome.png";
import firefox from "../../images/firefox.png";
import linux from "../../images/linux.png";
import opera from "../../images/opera.png";
import safari from "../../images/safari.png";
import windows from "../../images/windows.png";
import edge from "../../images/edge.png";
import ios from "../../images/ios.png";
import macos from "../../images/macos.png";
import { Tooltip } from "react-tooltip";


interface Props {
	ua: string;
}

const UserAgentIcons = ({ ua }: Props) => {
	const [uaInfo, setUaInfo] = useState({
		browserName: "",
		osName: ""
	});
	const toolTipId = useId();

	useEffect(() => {
		const parser = new UAParser(ua);
		const osName = parser.getOS().name;
		const browserName = parser.getBrowser().name;
		console.log({ osName, browserName });
		setUaInfo({
			browserName: browserName ? browserName.toLowerCase() : "",
			osName: osName ? osName.toLowerCase() : ""
		});
	}, [ua]);

	return (
		<div data-tooltip-id={toolTipId} data-tooltip-content={ua} className="inline-flex items-center p-2 border-b border-dotted border-gray-400 hover:border-green-500 cursor-help transition-colors duration-200">
			{
				(uaInfo.osName && getOSIcon(uaInfo.osName))
				&&
				<Icon src={getOSIcon(uaInfo.osName)!} alt={`${uaInfo.osName} icon`} />

			}
			{
				(uaInfo.browserName && getBrowserIcon(uaInfo.browserName))
				&&

				<Icon src={getBrowserIcon(uaInfo.browserName)!} alt={`${uaInfo.browserName} icon`} />
			}
			<Tooltip place="top" id={toolTipId} className="tooltip-custom" border="1px solid #32a852" />
		</div>
	);
};

const getOSIcon = (osName: string) => {
	switch (osName) {
		case "android":
			return android;
		case "ios":
			return ios;
		case "macos":
			return macos;
		case "windows":
			return windows;
		case "linux":
			return linux;
		default:
			return null;
	}
};

const getBrowserIcon = (browserName: string) => {
	switch (browserName) {
		case "chrome":
			return chrome;
		case "firefox":
			return firefox;
		case "safari":
			return safari;
		case "opera":
			return opera;
		case "edge":
			return edge;
		default:
			return null;
	}
};

const Icon = ({ src, alt }: { src: string; alt: string }) => (
	<img src={src} alt={alt} className="inline-block align-text-bottom h-[1em] mx-1" />
);

export default UserAgentIcons;
