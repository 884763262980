import React, { useCallback, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from '../../components/Toast';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import SanctumClient from '../../api';
import FooterPortal from '../../components/FooterPortal';
import Page from '../../layout/page';
import { ErrorCode } from '../../utils/errors';
import { getPublicKey } from 'nostr-tools';
import { hexToBytes } from '@noble/hashes/utils'
import { useDispatch } from '../../redux';
import { CheckSession } from '../../redux/userSlice';


const Authenticate: React.FC = () => {
	const { requestToken } = useParams() as { requestToken?: string };

	const navigate = useNavigate();
	const dispatch = useDispatch()
	const [nsec, setNsec] = useState("");


	const handleNsecAuth = useCallback(async () => {
		const handler = SanctumClient.getInstance();
		try {
			getPublicKey(hexToBytes(nsec))
		} catch {
			return toast.error(<Toast title="The nostr secret is invalid" message="Please input a valid nostr private key" />);
		}
		try {
			const res = await handler.AuthWithNsec(nsec, requestToken)
			await dispatch(CheckSession());
			if (requestToken && res.auth) {
				navigate(`/auth/confirmation/${requestToken}`, { replace: true });
			} else {
				navigate("/manage", { replace: true })
			}
		} catch (err) {
			const message = err instanceof Error ? err.message : ErrorCode.UNKNOWN_ERROR
			switch (message) {
				case ErrorCode.ACCESS_FORBIDDEN:
					toast.error(<Toast title='An unkown error occured' message='' />);
					setNsec("");
					break;
				case ErrorCode.REQUEST_TOKEN_NOT_FOUND_OR_EXPIRED:
					toast.error(<Toast title="The request token was not found or is expired" message="Please retry the auth flow from the start" />);
					navigate("/", { replace: true });
					break;
				default:
					toast.error(<Toast title='Unknown Error' message={message} />);
			}
		}
	}, [nsec, requestToken, navigate, dispatch])






	return (
		<Page title="Auth">
			<div className="flex flex-col justify-center items-center gap-1">
				<span className="text-base text-gray-200 font-medium">Auth with nsec</span>
				<TextInput placeholder="Enter your nostr private key" onChange={(e) => setNsec(e.target.value.trim())} value={nsec} />
				<Button onClick={handleNsecAuth}>
					Auth
				</Button>
			</div>
			<div className="flex items-center my-12 w-full">
				<div className="flex-grow border-t border-gray-300"></div>
				<span className="px-4 text-gray-500 font-semibold">OR</span>
				<div className="flex-grow border-t border-gray-300"></div>
			</div>
			<div className="text-center">
				<Link className="text-green-500 hover:cursor-pointer hover:underline" to={requestToken ? `/auth/email/${requestToken}` : "/auth/email"}>
					Auth with email
				</Link>
			</div>
			<FooterPortal>
				<div className="w-full p-4 text-center text-customGray font-medium text-sm">
					By proceeding you acknowledge that this is bleeding-edge software, and agree to the providers <a href="https://docs.shock.network/terms" target="_blank" rel="noreferrer" className="text-green-500 hover:cursor-pointer hover:underline">terms</a> regarding any services herein.
				</div>
			</FooterPortal>
		</Page>
	);
}

export default Authenticate;