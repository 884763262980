import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReducerAction } from ".";

import * as Types from "../api/autogenerated/types";
import SanctumClient from "../api";




interface UserInfo {
	accessTokens: Types.AccessTokenInfo[];
	keySlots: Record<string, number>;
	identifier: string
}
interface User {
	userInfo: UserInfo;
};

const initialState: User = {
	userInfo: {
		accessTokens: [],
		keySlots: {},
		identifier: ""
	},

}

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		updateUserInfo: (state, action: PayloadAction<UserInfo>) => {
			state.userInfo = action.payload
		},
		clear: (state) => {
			state.userInfo = {
				accessTokens: [],
				keySlots: {},
				identifier: ""
			};
		}
	}
});



export const { updateUserInfo, clear } = userSlice.actions;


export default userSlice.reducer;

export const fetchUserData = (): ReducerAction => async dispatch => {
	const handler = SanctumClient.getInstance();
	try {
		const res = await handler.GetUserInfo();
		dispatch(updateUserInfo({ keySlots: res.key_slots, accessTokens: res.user_access_tokens, identifier: res.identifier }));
	} catch (err) {
		// server will clear the cookie through header
		console.log("Session Error", err);
		dispatch(clear());
	}
};

export const CheckSession = (): ReducerAction => async dispatch => {
	const handler = SanctumClient.getInstance();
	const res = await handler.CheckSession();
	if (res.valid) {
		await dispatch(fetchUserData());
	} else {
		dispatch(clear());
	}
}