import React, { useEffect, ReactNode } from "react";
import ReactDOM from "react-dom";
import { Close } from "../../iconLibrary/svgIconLibrary";

interface ModalWrapperProps {
	isOpen: boolean;
	onClose: () => void;
	children: ReactNode;
	modalIndex?: number;
	className?: string;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
	isOpen,
	onClose,
	children,
	className,
	modalIndex = 0
}) => {
	useEffect(() => {
		const handleEscape = (event: KeyboardEvent) => {
			if (event.key === "Escape") onClose();
		};

		const disableScroll = () => {
			if (isOpen) {
				document.body.classList.add("overflow-hidden");
			} else {
				document.body.classList.remove("overflow-hidden");
			}
		};

		document.addEventListener("keydown", handleEscape);
		disableScroll();
		return () => {
			document.removeEventListener("keydown", handleEscape);
			document.body.classList.remove("overflow-hidden");
		};
	}, [onClose, isOpen]);

	const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if ((event.target as HTMLDivElement).id === `modal-backdrop-${modalIndex}`) onClose();
	};

	if (!isOpen) return null;

	const zIndex = 50 + modalIndex * 10;

	return ReactDOM.createPortal(
		<div
			id={`modal-backdrop-${modalIndex}`}
			onClick={handleBackdropClick}
			className="fixed inset-0 flex items-center justify-center bg-black/50"
			style={{ zIndex }}
		>
			<div
				role="dialog"
				aria-modal="true"
				className={`relative w-11/12 max-w-lg md:max-w-xl p-6 md:p-4 bg-darkBackground rounded-lg shadow-lg border-green-500 border overflow-hidden ${className}`}
				style={{ zIndex: zIndex + 1 }}
			>
				{/* Close Button */}
				<button
					onClick={onClose}
					className="absolute top-2 right-2 text-white hover:text-green-500 focus:outline-none"
					aria-label="Close"
				>
					<Close />
				</button>
				<div className="overflow-y-auto max-h-[calc(100vh-10rem)] my-11">
					{children}
				</div>
			</div>
		</div>,
		document.body
	);
};

export default ModalWrapper;
