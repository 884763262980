// This file was autogenerated from a .proto file, DO NOT EDIT!
import axios from 'axios'
import * as Types from './types'
export type ResultError = { status: 'ERROR', reason: string }

export type ClientParams = {
    baseUrl: string
    retrieveGuestAuth: () => Promise<string | null>
    retrieveAccessTokenAuth: () => Promise<string | null>
    retrieveUserAuth: () => Promise<string | null>
    encryptCallback: (plain: any) => Promise<any>
    decryptCallback: (encrypted: any) => Promise<any>
    deviceId: string
    checkResult?: true
}
export default (params: ClientParams) => ({
    Health: async (): Promise<ResultError | ({ status: 'OK' })> => {
        const auth = await params.retrieveGuestAuth()
        if (auth === null) throw new Error('retrieveGuestAuth() returned null')
        let finalRoute = '/api/health'
        const { data } = await axios.get(params.baseUrl + finalRoute, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            return data
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    GetNostrPubKey: async (): Promise<ResultError | ({ status: 'OK' } & Types.UserNostrPubKey)> => {
        const auth = await params.retrieveAccessTokenAuth()
        if (auth === null) throw new Error('retrieveAccessTokenAuth() returned null')
        let finalRoute = '/api/user/pubkey'
        const { data } = await axios.get(params.baseUrl + finalRoute, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.UserNostrPubKeyValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    GetNostrRelays: async (): Promise<ResultError | ({ status: 'OK' } & Types.NostrRelays)> => {
        const auth = await params.retrieveAccessTokenAuth()
        if (auth === null) throw new Error('retrieveAccessTokenAuth() returned null')
        let finalRoute = '/api/user/relays'
        const { data } = await axios.get(params.baseUrl + finalRoute, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.NostrRelaysValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    SignNostrEvent: async (request: Types.NostrSignRequest): Promise<ResultError | ({ status: 'OK' } & Types.NostrSignResponse)> => {
        const auth = await params.retrieveAccessTokenAuth()
        if (auth === null) throw new Error('retrieveAccessTokenAuth() returned null')
        let finalRoute = '/api/user/sign'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.NostrSignResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    Nip44Decrypt: async (request: Types.Nip44DecryptRequest): Promise<ResultError | ({ status: 'OK' } & Types.Nip44DecryptResponse)> => {
        const auth = await params.retrieveAccessTokenAuth()
        if (auth === null) throw new Error('retrieveAccessTokenAuth() returned null')
        let finalRoute = '/api/user/decrypt'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.Nip44DecryptResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    Nip44Encrypt: async (request: Types.Nip44EncryptRequest): Promise<ResultError | ({ status: 'OK' } & Types.Nip44EncryptResponse)> => {
        const auth = await params.retrieveAccessTokenAuth()
        if (auth === null) throw new Error('retrieveAccessTokenAuth() returned null')
        let finalRoute = '/api/user/encrypt'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.Nip44EncryptResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    RequestCallback: async (request: Types.RequestCallbackRequest): Promise<ResultError | ({ status: 'OK' } & Types.RequestCallbackResponse)> => {
        const auth = await params.retrieveGuestAuth()
        if (auth === null) throw new Error('retrieveGuestAuth() returned null')
        let finalRoute = '/api/guest/auth/callback'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.RequestCallbackResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    AuthorizeRequestToken: async (request: Types.AuthorizeRequestTokenRequest): Promise<ResultError | ({ status: 'OK' })> => {
        const auth = await params.retrieveUserAuth()
        if (auth === null) throw new Error('retrieveUserAuth() returned null')
        let finalRoute = '/api/guest/auth/requestToken/authorize'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            return data
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    AuthEmail: async (request: Types.AuthRequest): Promise<ResultError | ({ status: 'OK' } & Types.AuthResponse)> => {
        const auth = await params.retrieveGuestAuth()
        if (auth === null) throw new Error('retrieveGuestAuth() returned null')
        let finalRoute = '/api/guest/auth/email'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.AuthResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    GetAccessTokenRecords: async (request: Types.GetAccessTokenRecordsRequest): Promise<ResultError | ({ status: 'OK' } & Types.GetAccessTokenRecordsResponse)> => {
        const auth = await params.retrieveUserAuth()
        if (auth === null) throw new Error('retrieveUserAuth() returned null')
        let finalRoute = '/api/user/records'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.GetAccessTokenRecordsResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    GetUserInfo: async (): Promise<ResultError | ({ status: 'OK' } & Types.GetUserInfoResponse)> => {
        const auth = await params.retrieveUserAuth()
        if (auth === null) throw new Error('retrieveUserAuth() returned null')
        let finalRoute = '/api/user/info'
        const { data } = await axios.get(params.baseUrl + finalRoute, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.GetUserInfoResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    GetRequestTokenData: async (request: Types.GetRequestTokenDataRequest): Promise<ResultError | ({ status: 'OK' } & Types.GetRequestTokenDataResponse)> => {
        const auth = await params.retrieveGuestAuth()
        if (auth === null) throw new Error('retrieveGuestAuth() returned null')
        let finalRoute = '/api/guest/requestToken'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.GetRequestTokenDataResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    GetAuthRequestInfo: async (request: Types.GetAuthRequestInfoRequest): Promise<ResultError | ({ status: 'OK' } & Types.GetAuthRequestInfoResponse)> => {
        const auth = await params.retrieveGuestAuth()
        if (auth === null) throw new Error('retrieveGuestAuth() returned null')
        let finalRoute = '/api/guest/authInfo'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.GetAuthRequestInfoResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    DeleteAccessToken: async (request: Types.DeleteAccessTokenRequest): Promise<ResultError | ({ status: 'OK' })> => {
        const auth = await params.retrieveUserAuth()
        if (auth === null) throw new Error('retrieveUserAuth() returned null')
        let finalRoute = '/api/user/access/delete'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            return data
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    Nip98Event: async (request: Types.Nip98EventRequest): Promise<ResultError | ({ status: 'OK' } & Types.Nip98EventResponse)> => {
        const auth = await params.retrieveAccessTokenAuth()
        if (auth === null) throw new Error('retrieveAccessTokenAuth() returned null')
        let finalRoute = '/api/user/nip98'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.Nip98EventResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    AuthWithNsec: async (request: Types.AuthWithNsecRequest): Promise<ResultError | ({ status: 'OK' } & Types.AuthWithNsecResponse)> => {
        const auth = await params.retrieveGuestAuth()
        if (auth === null) throw new Error('retrieveGuestAuth() returned null')
        let finalRoute = '/api/guest/auth/nsec'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.AuthWithNsecResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    SignupWithNsecAccessToken: async (request: Types.SignupWithNsecAccessTokenRequest): Promise<ResultError | ({ status: 'OK' } & Types.SignupWithNsecAccessTokenResponse)> => {
        const auth = await params.retrieveGuestAuth()
        if (auth === null) throw new Error('retrieveGuestAuth() returned null')
        let finalRoute = '/api/guest/auth/nsec/access'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.SignupWithNsecAccessTokenResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    CheckSession: async (): Promise<ResultError | ({ status: 'OK' } & Types.CheckSessionResponse)> => {
        const auth = await params.retrieveGuestAuth()
        if (auth === null) throw new Error('retrieveGuestAuth() returned null')
        let finalRoute = '/api/guest/user/session'
        const { data } = await axios.get(params.baseUrl + finalRoute, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            const result = data
            if (!params.checkResult) return { status: 'OK', ...result }
            const error = Types.CheckSessionResponseValidate(result)
            if (error === null) { return { status: 'OK', ...result } } else return { status: 'ERROR', reason: error.message }
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
    UpdateAccessTokenInfo: async (request: Types.UpdateAccessTokenInfoRequest): Promise<ResultError | ({ status: 'OK' })> => {
        const auth = await params.retrieveUserAuth()
        if (auth === null) throw new Error('retrieveUserAuth() returned null')
        let finalRoute = '/api/user/accessToken/info/update'
        const { data } = await axios.post(params.baseUrl + finalRoute, request, { headers: { 'authorization': auth } })
        if (data.status === 'ERROR' && typeof data.reason === 'string') return data
        if (data.status === 'OK') {
            return data
        }
        return { status: 'ERROR', reason: 'invalid response' }
    },
})
