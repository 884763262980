import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import SanctumClient from "../../../api"
import { GetRequestTokenDataResponse } from "../../../api/autogenerated/types"
import { useDispatch, useSelector } from "../../../redux"
import { ErrorCode } from "../../../utils/errors"
import { CheckSession } from "../../../redux/userSlice"


const RequestTokenFlow: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { requestToken } = useParams() as { requestToken: string };
	const { identifier } = useSelector(state => state.user.userInfo);
	const [error, setError] = useState("");
	const [requestTokenData, setRequestTokenData] = useState<GetRequestTokenDataResponse>();


	useEffect(() => {
		const handler = SanctumClient.getInstance();
		const getRequestTokenInfo = async () => {
			await dispatch(CheckSession());
			try {
				const res = await handler.GetRequestTokenData(requestToken);
				await dispatch(CheckSession());
				setRequestTokenData(res);
			} catch (err) {
				const message = err instanceof Error ? err.message : ErrorCode.UNKNOWN_ERROR
				switch (message) {
					case ErrorCode.REQUEST_TOKEN_NOT_FOUND_OR_EXPIRED:
						setError("Request token not found or expired. Please start the flow again.");
						break;
					default:
						setError("An unknown error occured.");
				}

			}
		}
		getRequestTokenInfo()
	}, [requestToken, dispatch])


	useEffect(() => {
		if (!requestTokenData) return;

		if (identifier) {
			navigate(`/auth/confirmation/${requestToken}`, { replace: true });
		} else {
			navigate(`/auth/email/${requestToken}`, { replace: true });
		}


	}, [requestTokenData, identifier, navigate, requestToken])



	return (

		<>
			<main className="flex-grow flex flex-col justify-center items-center px-6">
				{
					error
						?
						<>
							<h1 className="text-3xl font-bold mb-4">An Error Occurred</h1>
							<p className="text-lg text-red-500 mb-6">{error}</p>
						</>
						:
						<>

							<div className="w-16 h-16 border-4 border-green-500 border-t-transparent rounded-full animate-spin mb-6"></div>
							<h1 className="text-2xl font-medium">Reticulating splines...</h1>
							<p className="text-customGray mt-4">Please wait while we prepare everything for you.</p>
						</>
				}
			</main>


			<footer className="w-full p-4 text-center text-customGray font-medium text-sm">
				By proceeding you acknowledge that this is bleeding-edge software, and agree to the providers <a href="https://docs.shock.network/terms" target="_blank" rel="noreferrer" className="text-green-500 hover:cursor-pointer hover:underline">terms</a> regarding any services herein.
			</footer>
		</>

	)
}

export default RequestTokenFlow