import React, { useMemo, useState } from "react";
import { useSelector } from "../../redux";
import { formatTimestamp, getSndLevelDomain } from "../../utils/utility";
import UserAgentIcons from "../../components/UserAgentIcons";
import * as Types from "../../api/autogenerated/types";
import AccessTokenModal from "../../components/accessTokenModal";
import Page from "../../layout/page";
import FooterPortal from "../../components/FooterPortal";
import ShocknetLogo from "../../images/shocknet.png"
import ModalWrapper from "../../components/Modal";

const Manage: React.FC = () => {
	const { userInfo } = useSelector((state) => state.user);
	const [selectedModal, setSelectedModal] =
		useState<Types.AccessTokenInfo | null>(null);


	const tableRows = useMemo(() => {
		return (
			<>
				{userInfo.accessTokens.map((t) => {
					return (
						<tr key={t.access_token} onClick={() => setSelectedModal(t)}>
							<TableRow className="text-sm md:text-base px-[14px]">
								{userInfo.keySlots[t.public_key]}
							</TableRow>
							{
								t.label
									?
									<TableRow className="text-sm md:text-base text-gray-300 text-center px-[14px]">
										{t.label}
									</TableRow>
									:
									<TableRow className="text-sm md:text-base text-green-500 underline text-center px-[14px]">
										{getSndLevelDomain(t.origin)}
									</TableRow>
							}
							<TableRow>
								<span>
									<UserAgentIcons ua={t.user_agent} />
								</span>
							</TableRow>
							<TableRow className="date">
								{t.last_used === 0
									? "Hasn't been used yet"
									: formatTimestamp(t.last_used)}
							</TableRow>
						</tr>
					);
				})}
			</>
		);
	}, [userInfo]);

	return (
		<Page title="Token Management">
			<div className="py-3 px-2 text-customGray w-full flex justify-center items-center">
				<table className="border-collapse w-full text-center m-auto md:w-[655px]">
					<thead>
						<tr>
							<TableHeader text="Slot" />
							<TableHeader text="Label" />
							<TableHeader text="Agent" />
							<TableHeader text="Last" />
						</tr>
					</thead>
					<tbody>{tableRows}</tbody>
				</table>
			</div>
			<ModalWrapper
				isOpen={selectedModal !== null}
				onClose={() => setSelectedModal(null)}
				modalIndex={0}
			>
				<AccessTokenModal
					info={selectedModal!}
					hide={() => setSelectedModal(null)}
				/>
			</ModalWrapper>

			<FooterPortal>
				<div className="w-full p-4 text-center text-customGray font-medium text-sm">
					<div className="flex flex-col justify-center items-center">
						<img className="h-6 w-auto" src={ShocknetLogo} alt="shocknet" />
						<span>
							<a className="text-green-500 hover:cursor-pointer hover:underline" href="https://docs.shock.network" target="_blank" rel="noreferrer">
								Docs
							</a>
							&nbsp;and&nbsp;
							<a className="text-green-500 hover:cursor-pointer hover:underline" href="https://docs.shock.network" target="_blank" rel="noreferrer">
								Terms
							</a>
						</span>
					</div>
				</div>
			</FooterPortal>
		</Page>

	);
};

const TableHeader = ({ text }: { text: string }) => <th className="border-solid border-[#666666] border-[1px] text-base text-customGray py-2 px-3">{text}</th>

interface TableRowProps {
	className?: string;
	children: React.ReactNode;
}

const TableRow = ({ className, children, ...props }: TableRowProps & React.HTMLAttributes<HTMLTableCellElement>) => (
	<td
		className={`
			border-solid border-[#666666] border-[1px] 
			text-[12px] md:text-base 
			py-2 px-1 md:px
			cursor-pointer
			${props.onClick ? 'cursor-pointer' : ''} 
			${className}
		`}
		{...props}
	>
		{children}
	</td>
);

export default Manage;
