import * as Type from './type';


const Toast = ({ title, message }: Type.Props) => {
	return (
		<div className='flex flex-col justify-center items-start gap-2'>
			<span className='text-lg font-bold'>
				{title}
			</span>
			<span className='text-base font-semibold'>
				{message}
			</span>
		</div>
	)
}

export default Toast;