import { UseInfiniteScroll } from "../../hooks/useEventsInfiniteLoader";


type LoaderProps = Pick<
  UseInfiniteScroll,
  "isLoading" | "loadMoreCallback" | "isLastPage"
>;

export default function InfiniteLoader({
  isLoading,
  isLastPage,
  loadMoreCallback,
}: LoaderProps) {
  if (isLoading) return <div className="flex justify-center"><div className="w-12 h-12 border-4 border-green-500 border-t-transparent rounded-full animate-spin mb-6"></div></div>;

  if (isLastPage) {
    return (
      <div className="flex items-center justify-center mt-4 p-4 text-sm text-gray-400 bg-gray-800 rounded-md shadow-md">
        <span>No more items to load</span>
      </div>
    );
  }

  return <div className=" mb-[50px] h-12 w-12" ref={loadMoreCallback}></div>;
}