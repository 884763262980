interface Props {
	children: React.ReactNode
}
const OutlinedButton = ({ children, className, ...props }: Props & React.HTMLAttributes<HTMLButtonElement>) => {
	return (
		<button
			{...props}
			className={`border border-solid text-center
				border-green-500 font-medium shadow-md bg-transparent
				hover:bg-green-500 hover:text-white active:bg-green-600
				rounded-md px-4 md:px-8 py-4
			${className}`}>
			{children}
		</button>
	)
}

export default OutlinedButton