import { DisplayEvent } from "../../utils/types";

interface Props {
	events: DisplayEvent[]
}


const SimpleEvents = ({ events }: Props) => {
	return (
		<div className="space-y-2 text-customGray w-full">
			{events.map((e) => (
				<div
					key={`${e.type}${e.datestring}${e.nevent}`}
					className="flex items-center text-sm font-medium text-customGray"
				>
					<span className="flex-shrink-0 after:content-['|'] after:mx-1">{e.datestring}</span>
					<span className="flex-grow underline truncate decoration-customGray decoration-1 underline-offset-2 ">
						{e.type}&nbsp;{e.nevent}
					</span>

					<span className="flex items-center space-x-1 flex-shrink-0 before:content-['|'] before:mx-1">
						<img
							src={e.flagSrc}
							alt={e.flagAlt}
							className="w-5 h-auto rounded-sm"
						/>
						<span>{e.state}</span>
					</span>
				</div>
			))}
		</div>
	);
};

export default SimpleEvents;