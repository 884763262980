import { useState, useCallback, useRef, useEffect } from "react";
import * as Types from "../api/autogenerated/types";

import { DisplayEvent } from "../utils/types";
import { FormatEventToDisplayEvent } from "../utils/utility";
import SanctumClient from "../api";


export interface UseInfiniteScroll {
	isLoading: boolean;
	loadMoreCallback: (el: HTMLDivElement) => void;
	events: DisplayEvent;
	isLastPage: boolean;
	pullSize: number;
	accessToken: string;
}






const useEventsInfiniteScroll = (
	pullsize: number,
	accessToken: string,
) => {
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState<number | null>(1);
	const [dynamicEvents, setDynamicEvents] = useState<DisplayEvent[]>([]);
	const [isLastPage, setIsLastPage] = useState(false);
	const [waitForInitialLoad, setWaitForInitialLoad] = useState(false);
	const observerRef = useRef<IntersectionObserver>();
	const loadMoreTimeout: NodeJS.Timeout = setTimeout(() => null, 500);
	const loadMoreTimeoutRef = useRef<NodeJS.Timeout>(loadMoreTimeout);


	const getEvents = useCallback(() => {
		const handler = SanctumClient.getInstance()
		setIsLoading(true);
		clearTimeout(loadMoreTimeoutRef.current);

		loadMoreTimeoutRef.current = setTimeout(async () => {
			const res = await handler.GetAccessTokenRecords(accessToken, page as number, pullsize);
			setPage(page as number + 1);
			const retrievedEvents = res.records;
			if (retrievedEvents.length === 0) {
				setIsLoading(false);
				setIsLastPage(true);
			} else {
				const formattedEvents = await FormatEventToDisplayEvent(retrievedEvents);
				const newDynamicEvents = [
					...dynamicEvents,
					...formattedEvents
				];
				setDynamicEvents(newDynamicEvents);
				setIsLastPage(res.is_last_page);
				setIsLoading(false);
			}
		}, 500)
	}, [dynamicEvents, page, pullsize, accessToken]);

	const handleObserver = useCallback(
		(entries: any[]) => {
			const target = entries[0];

			if (target.isIntersecting && !waitForInitialLoad) {
				getEvents();
			}
		},

		[getEvents, waitForInitialLoad]
	);

	const loadMoreCallback = useCallback(
		(el: HTMLDivElement) => {
			if (isLoading) return;
			if (observerRef.current) observerRef.current.disconnect();

			const option: IntersectionObserverInit = {
				root: null,
				rootMargin: "0px",
				threshold: 0
			};
			observerRef.current = new IntersectionObserver(handleObserver, option);

			if (el) observerRef.current.observe(el);
		},
		[handleObserver, isLoading]
	);

	return {
		isLoading,
		loadMoreCallback,
		dynamicEvents,
		setDynamicEvents,
		setIsLastPage,
		isLastPage,

	};
};

export default useEventsInfiniteScroll;
